import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import classNames from 'classnames';

import './Tile.scss';

export default ({ to, image, heading, color, alt }) => {
    return (
        <Link
            to={to}
            className={classNames({
                tile: true,
                [`tile--${color}`]: !!color,
            })}
            activeClassName="tile--active"
        >
            <div className="tile__wrapper">
                {heading && <h3 className="tile__heading">{heading}</h3>}
                <Img fluid={image.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" alt={alt} />
            </div>
        </Link>
    );
};

export const query = graphql`
    fragment TileImage on File {
        childImageSharp {
            fluid(maxWidth: 930, maxHeight: 1010, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`;
