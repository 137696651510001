import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Tile from './Tile';

import './Tiles.scss';

export default () => {
    return (
        <StaticQuery
            query={graphql`
                query TilesQuery {
                    image1: file(relativePath: { eq: "unsplash-5SUt9q8jQrQ.jpg" }) {
                        ...TileImage
                    }
                    image2: file(relativePath: { eq: "unsplash-14BVc2mD9Bk.jpg" }) {
                        ...TileImage
                    }
                    image3: file(relativePath: { eq: "unsplash-gqegalf39WM.jpg" }) {
                        ...TileImage
                    }
                    image4: file(relativePath: { eq: "unsplash-VSrHD079L78.jpg" }) {
                        ...TileImage
                    }
                }
            `}
            render={(data) => (
                <div className="tiles">
                    <div className="tiles__section">
                        <div className="tiles__row">
                            <div className="tiles__tile">
                                <Tile
                                    to="/who/"
                                    image={data.image1}
                                    heading="Who are Black Dot"
                                    color="gold"
                                    alt="Ariel view of person standing on a concrete foor"
                                />
                            </div>
                            <div className="tiles__tile">
                                <Tile
                                    to="/where/"
                                    image={data.image2}
                                    heading="Where is Black Dot active"
                                    color="purple"
                                    alt="Man standing outside of a cave, looking out to the sky"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="tiles__section">
                        <div className="tiles__row">
                            <div className="tiles__tile">
                                <Tile
                                    to="/what/"
                                    image={data.image3}
                                    heading="What we do"
                                    color="green"
                                    alt="Woman sitting outside with trees in the background"
                                />
                            </div>
                            <div className="tiles__tile">
                                <Tile
                                    to="/expertise/"
                                    image={data.image4}
                                    heading="Our Expertise"
                                    color="turquoise"
                                    alt="Abstract view of blue walls with circule holes in them"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        />
    );
};
